<template>
  <div class="home">
    <section class="main">
      <div class="container">
        <div class="main__title">
          <div class="row">
            <div class="col-md-4 icon__block">
<!--                            <img src="img/main__preview.png" class="main__image" alt="">-->
              <video
                  @mouseover="play"
                  @mouseleave="stop"
                  ref="logo"
                  muted
                  id="myVideo"
                  playsinline
                  allow="autoplay"
              >
                <source src="video/logo2.mp4" type="video/mp4">
              </video>
            </div>
            <div class="col-md-8 col-lg-6 col-xl-5">
              <h1 class="main__title__h1 mb-4">
                <div class="main__title__h1__title mb-3 c-p">
                  {{ $i18n.t('main.s1h1') }}
                </div>
                <div class="main__title__h1__subTitle">
                  {{ $i18n.t('main.s1h2') }}
                </div>
              </h1>
              <a href="https://app.exchanity.com/" class="btn btn-primary">
                {{ $i18n.t('main.signUp') }}
              </a>

            </div>
          </div>
        </div>
        <div class="main__text">
          <div class="row justify-content-md-end">
            <div class="col-md col-lg-2 main__text__item">
              <div class="icon mb-1">
                <img src="img/icons/a_1.svg" alt="">
              </div>
              <div class="text-default">
                {{ $i18n.t('main.s1el1') }}
              </div>
            </div>
            <div class="col-md col-lg-2 main__text__item">
              <div class="icon mb-1">
                <img src="img/icons/a_2.svg" alt="">
              </div>
              <div class="text-default">
                {{ $i18n.t('main.s1el2') }}
              </div>
            </div>
            <div class="col-md col-lg-2 main__text__item">
              <div class="icon mb-1">
                <img src="img/icons/a_3.svg" alt="">
              </div>
              <div class="text-default">
                {{ $i18n.t('main.s1el4') }}
                <router-link :to="{name: 'Products', params: {scroll: 'fees'}}">{{ $i18n.t('main.s1el4Link') }}</router-link>
              </div>
            </div>
            <div class="col-md col-lg-2 main__text__item">
              <div class="icon mb-1">
                <img src="img/icons/a_4.svg" alt="">
              </div>
              <div class="text-default">
                {{ $i18n.t('main.s1el5') }}
                <a @click="slide" href="#benefits">{{ $i18n.t('main.s1el5Link') }}</a>
              </div>
            </div>
            <div class="col-md col-lg-2 main__text__item">
              <div class="icon mb-1">
                <img src="img/icons/a_5.svg" alt="">
              </div>
              <div class="text-default">
                {{ $i18n.t('main.s1el3') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="assets">
      <div class="container">
        <h2 class="mb-4 d-flex justify-content-between">
          {{ $i18n.t('main.assets') }}
          <a class="f-b crypto__link" target="_blank" href=""> {{ $i18n.t('main.viewAll') }} </a>
        </h2>
        <digital-assets></digital-assets>
      </div>
    </section>
    <section class="how">
      <div class="container">
        <h2 class="mb-4">
          {{ $i18n.t('main.howWork') }}
        </h2>

        <div id="howWorks"
             v-touch:swipe.prevent.stop="swipe"
             ref="carousel"
             data-bs-touch="false"
             data-bs-ride="carousel"
             class="carousel carousel-fade">
          <div class="howWorks__links carousel-indicators">
            <div class="how__ind mb-4">
              <a>
                <div class="separator mb-3" disabled></div>
              </a>
              <a href="" class="d-block slide_action active" data-bs-target="#howWorks" data-bs-slide-to="0">
                <div class="separator mb-3"></div>
                <div class="text-default">
                  {{ $i18n.t('main.s2el1') }}
                </div>
              </a>
              <a href="" class="d-block slide_action" data-bs-target="#howWorks" data-bs-slide-to="1">
                <div class="separator mb-3"></div>
                <div class="text-default">
                  {{ $i18n.t('main.s2el2') }}
                </div>
              </a>
              <a href="" class="d-block slide_action" data-bs-target="#howWorks" data-bs-slide-to="2">
                <div class="separator mb-3"></div>
                <div class="text-default">
                  {{ $i18n.t('main.s2el3') }}
                </div>
              </a>
              <a href="" class="d-block slide_action" data-bs-target="#howWorks" data-bs-slide-to="3">
                <div class="separator mb-3"></div>
                <div class="text-default">
                  {{ $i18n.t('main.s2el4') }}
                </div>
              </a>
              <a href="https://app.exchanity.com/" class="d-md-block d-none ">
                <div class="separator mb-3" disabled></div>
                <span class="btn btn-outline-secondary">{{ $i18n.t('main.signUp') }}</span>
              </a>
            </div>

          </div>
          <div class="carousel-inner" >
            <div class="carousel-item  active" data-bs-interval="12000">
              <div class="works__image__box">
                <img :src="imageUrl" alt="">
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="24000" >
              <scrolled-slide
                :activeSlide="activeSlide"
              />
            </div>
            <div class="carousel-item" data-bs-interval="12000">
              <div class="works__image__box">
                <img src="img/works2.svg" alt="">
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="12000">
              <div class="works__image__box">

                  <img src="img/works3.png" class="works__image__custom" alt="">

              </div>
            </div>
          </div>
        </div>

        <div class="d-sm-none mt-5">
          <a class="btn btn-outline-secondary mw-100" href="https://app.exchanity.com/">{{ $i18n.t('main.signUp') }}</a>
        </div>
      </div>
    </section>
    <section class="system mb-0">
      <div class="container">
        <h2 class="mb-4">
          {{ $i18n.t('main.system') }}
        </h2>

        <div id="carouselExampleCaptions"
             data-bs-config='{"interval":3000, "delay": 0}'
             data-bs-ride="carousel"
             class="carousel carousel-fade">
          <div class="carousel-indicators">
            <div class="row">
              <div class="col-md-4 ">
                <a class="separator active" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                   aria-current="true"></a>
              </div>
              <div class="col-md-4">
                <a class="separator" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></a>
              </div>
              <div class="col-md-4">
                <a class="separator" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></a>
              </div>
            </div>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active pt-4 pb-4">
              <div class="row justify-content-between">
                <div class="col-md-6 order-2">
                  <h2 class="mb-4">
                    {{ $i18n.t('main.s3Funct') }}
                  </h2>
                  <div class="system__max">
                    <p class="mb-4 text-default ">
                      {{ $i18n.t('main.s3FunctText1') }}
                    </p>
                    <p class="text-default">
                      {{ $i18n.t('main.s3FunctText2') }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 order-md-2">
                  <div class="separator d-md-none mb-4"></div>
                  <img src="img/ex1.svg" alt="">
                </div>
              </div>


            </div>
            <div class="carousel-item pt-4 pb-4">
              <div class="row justify-content-between">
                <div class="col-md-6 order-2">
                  <h2 class="mb-4">
                    {{ $i18n.t('main.s3Account') }}
                  </h2>
                  <div class="system__max">
                    <p class="mb-4 text-default ">
                      {{ $i18n.t('main.s3AccountText') }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 order-md-2">
                  <div class="separator d-md-none mb-4"></div>
                  <img src="img/ex2.png" alt="">
                </div>
              </div>
            </div>
            <div class="carousel-item pt-4 pb-4">
              <div class="row justify-content-between">
                <div class="col-md-6 order-2">
                  <h2 class="mb-4">
                    {{ $i18n.t('main.s3Statistics') }}
                  </h2>
                  <div class="system__max">
                    <p class="mb-4 text-default ">
                      {{ $i18n.t('main.s3StatisticsText1') }}
                    </p>
                    <p class="text-default">
                      {{ $i18n.t('main.s3StatisticsText2') }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 order-md-2">
                  <div class="separator d-md-none mb-4"></div>
                  <img src="img/ex3.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="benefits pt-5" id="benefits">
      <div class="container">
        <div class="benefits__container">
          <div class="row justify-content-xl-end justify-content-between">
            <div class="col-xl-3 benefits__col order-1">
              <h2 style="max-width: 200px">
                {{ $i18n.t('main.bonuses') }}
              </h2>
            </div>
            <article class="col-xl-4 col-lg-6 col-md-7 order-3 order-lg-2 benefits__col">
              <div class="benefits__item mb-5">
                <div class="icon">
                  <img src="img/icons/b_1.svg" alt="">
                </div>
                <div class="text-default">
                  {{ $i18n.t('main.s4el1') }}
                </div>
              </div>

              <div class="benefits__item mb-5">
                <div class="icon">
                  <img src="img/icons/b_2.svg" alt="">
                </div>
                <div class="text-default">
                  {{ $i18n.t('main.s4el2') }}
                </div>
              </div>

              <div class="benefits__item mb-5">
                <div class="icon">
                  <img src="img/icons/b_3.svg" alt="">
                </div>
                <div class="text-default">
                  {{ $i18n.t('main.s4el3') }}
                </div>
              </div>

              <div class="benefits__item mb-5">
                <div class="icon">
                  <img src="img/icons/b_4.svg" alt="">
                </div>
                <div class="text-default">
                  {{ $i18n.t('main.s4el4') }}
                </div>
              </div>
              <div class="benefits__item mb-5">
                <div class="icon">
                  <img src="img/icons/b_5.svg" alt="">
                </div>
                <div class="text-default">
                  {{ $i18n.t('main.s4el5') }}
                </div>
              </div>
            </article>
            <div class="col-lg-4 order-2 col-md-5">
              <img src="img/benefits__img.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import {slide} from "@/utills/all";
import DigitalAssets from "../components/digital-assets";
import ScrolledSlide from "@/components/scrolled-slide";

export default {
  name: 'Home',
  components: {DigitalAssets, ScrolledSlide},
  data() {
    return {
      activeSlide: null
    }
  },
  computed: {
    imageUrl() {
      return this.$i18n.locale === 'en' ? 'img/works0.svg' : 'img/works0-1.svg'
    },

  },
  methods: {
    slide,
    play() {
      this.$refs.logo.play();
      this.$refs.logo.setAttribute('loop', 'loop')
    },
    stop() {
      this.$refs.logo.removeAttribute('loop')
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    swipe(event) {

      // eslint-disable-next-line no-undef
      const carousel = bootstrap.Carousel.getInstance(this.$refs.carousel)
      if (event === 'left' || event === 'top') {
       carousel.next();
      } else if (event === 'right' || event === 'bottom') {
        carousel.prev();
      }
    }
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
  mounted() {
    document.querySelector('.icon__block').classList.remove('show');
    setTimeout(()=>{
      document.querySelector('.icon__block').classList.add('show');
    },500)

    document.querySelector('body').scrollIntoView({
      behavior: 'smooth'
    });
    // eslint-disable-next-line no-undef
    new bootstrap.Carousel('#carouselExampleCaptions')
    this.play();
    setTimeout(() => {
      this.stop();
    }, 4000);

    this.$refs.carousel.addEventListener('slide.bs.carousel',(e)=>{
      this.activeSlide = e.to;
    })
  }
}
</script>

