<template>
  <div class="coinPriceBlock-container coinPriceBlock-container--light">
  </div>
</template>

<script>
export default {
  name: 'DigitalAssets',
  data() {
    return {
      interval: null
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.getItems()
    }, 500)
  },
  methods: {
    getItems() {
      const items = document.querySelectorAll('.coinPriceBlock');
      const newItems = [];
      items.forEach((el,i)=>{
        newItems[i] = el.cloneNode(true);
      })
      if (newItems && newItems.length) {
        for (let i of newItems) {
          const btn = document.createElement('div');
          btn.classList.add('btn')
          btn.classList.add('btn-primary')
          btn.innerText = `Buy | Sell`;

          // todo тут ссылки добавить можно
          i.append(btn);
          const bg = document.createElement('div');
          bg.classList.add('coinPriceBlock__border');
          bg.append(i);
          this.$el.append(bg);
          // this.$el.append(i);
        }
        if (this.interval) {
          clearInterval(this.interval)
        }
      }
    }
  }
}
</script>

