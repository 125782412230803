<template>
  <div class="about">
    <section class="main">
      <div class="container">
        <div class="row">
          <div class="col-md-2 pb-3 pt-5 pb-md-3 pt-md-0 offset-lg-2 ">
            <h2> {{$i18n.t('nav.about')}}</h2>
          </div>
          <div class="col-lg-8 col-md-10">
            <h2>{{ $i18n.t('aboutUs.main') }}
            </h2>
          </div>
        </div>
      </div>
    </section>
    <section class="form mb-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 pt-5 pt-lg-0  pe-lg-5">
            <div class="d-flex flex-column flex-sm-row pb-3">
              <h2 class="element__h py-2 py-sm-0 d-flex align-items-start"><img class="icon mr-1" src="img/icons/heart.svg" alt=""><span>{{ $i18n.t('aboutUs.formH1') }}</span></h2>
              <div>
                <p>
                  {{ $i18n.t('aboutUs.formText1') }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row ">
              <h2 class="element__h py-2 py-sm-0 d-flex align-items-start"><img class="icon" src="img/icons/document.svg" alt=""><span>{{ $i18n.t('aboutUs.formH2') }}</span></h2>
              <div>
                <p>
                  {{ $i18n.t('aboutUs.formText2') }}
                  <a href="mailto:hr@exchanity.com">hr@exchanity.com</a>
                  {{ $i18n.t('aboutUs.formText2end') }}

                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 pt-5 pt-lg-0 " id="form">
            <div class="d-flex flex-column flex-sm-row">
              <h2 class="element__h py-2 py-sm-0 d-flex align-items-start"><img class="icon" src="img/icons/mail.svg" alt=""><span>{{ $i18n.t('aboutUs.formH3') }}</span></h2>
              <div>
                <form action="send.php" method="post">
                  <input name="name" required  class="mb-2" :placeholder="$i18n.t('aboutUs.formName')" type="text">
                  <input name="email" required  class="mb-2" placeholder="E-mail" type="email">
                  <input name="subject" required  class="mb-2" :placeholder="$i18n.t('aboutUs.formSubject')" type="text">
                  <textarea name="text" required class="mb-2" :placeholder="$i18n.t('aboutUs.formTextarea')"></textarea>
                  <button class="btn btn-primary" type="submit">{{$i18n.t('aboutUs.formSend')}}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contacts" class="map pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">

            <iframe v-if="$i18n.locale === 'ru'"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d175647.9326783315!2d25.238129439778263!3d54.659652077987566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd94102ed84797%3A0xeb4856846655bdc5!2zR2VkaW1pbm8gcHIuIDIwLCBWaWxuaXVzIDAxMTAzLCDQm9C40YLQstCw!5e0!3m2!1sru!2sru!4v1657293757925!5m2!1sru!2sru"
                width="850"
                height="300"
                style="border:0; max-width: 100%;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <iframe v-if="$i18n.locale === 'en'"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2306.2775177090984!2d25.2785399!3d54.687143899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd94102ed84797%3A0xeb4856846655bdc5!2sGedimino%20pr.%2020%2C%20Vilnius%2001103%2C%20Lithuania!5e0!3m2!1sen!2sru!4v1657296816818!5m2!1sen!2sru"
                width="850"
                height="300"
                style="border:0; max-width: 100%;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="col-md-4 pt-3 pt-md-0 ps-md-5">
            <p>
              {{$i18n.t('aboutUs.address')}}
              <br>
              {{$i18n.t('aboutUs.address2')}}              <br>
              {{$i18n.t('aboutUs.address3')}} <br> <br>
              Email: <a href="mailto:support@exchanity.com">support@exchanity.com</a> <br> <br>
              <span class="d-none d-md-inline-block" > Tel: +370 52078762</span>
            <span class="d-inline-block d-md-none">  Tel:  <a  href="tel:+370 52078762">+370 52078762</a></span>
<!--              Адрес: набережная Обводного канала, <br>-->
<!--              15Б, Санкт-Петербург, 191119 <br>-->
<!--              Часы работы: <br>-->
<!--              Скоро откроется ⋅ 10:00 <br>-->
<!--              Телефон: 8 (981) 737-66-66-->
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "About",
  data(){
    return{
    form:{
      name: "",
      email: "",
      subject: "",
      text: "",
        },
      mapSrcEn : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2306.2775177090984!2d25.2785399!3d54.687143899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd94102ed84797%3A0xeb4856846655bdc5!2sGedimino%20pr.%2020%2C%20Vilnius%2001103%2C%20Lithuania!5e0!3m2!1sen!2sru!4v1657296816818!5m2!1sen!2sru',
      mapSrcRu : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d175647.9326783315!2d25.238129439778263!3d54.659652077987566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd94102ed84797%3A0xeb4856846655bdc5!2zR2VkaW1pbm8gcHIuIDIwLCBWaWxuaXVzIDAxMTAzLCDQm9C40YLQstCw!5e0!3m2!1sru!2sru!4v1657293757925!5m2!1sru!2sru'

    }
  },
  computed:{
  },
  methods:{
    send(e){
      // this.form.name > 2

      e.target.submit()

    }
  },
  created() {},
  mounted() {
    if (this.$route.params.scroll === 'form'){
      document.querySelector('#form').scrollIntoView({
        behavior: 'smooth'
      });
    }else {
      document.querySelector('body').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>
