<template>
<div class="products">
  <section class="main">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-lg-2 offset-lg-2">
          <h2 class="pb-md-0 pb-4 pt-4 pt-md-0"> {{$i18n.t('product.mainH1')}}</h2>
        </div>
        <div class="col-md-8">
          <h1 class="main__title__h1__title mb-3 c-p">{{$i18n.t('product.mainText1')}}
          </h1>
        </div>
        <div class="col-md-4 col-lg-2 offset-lg-2 pt-4">
          <h2> {{$i18n.t('product.mainH2')}}</h2>
<!--          <h2 v-if="$i18n.locale === 'en'"> {{$i18n.t('product.mainH2')}}</h2>-->
<!--          <h2 v-else> Это <br class="d-none d-md-inline"> просто</h2>-->
        </div>
        <div class="col-md-8 pt-4">
          <h2 class="mb-5">{{$i18n.t('product.mainText2')}}
          </h2>
          <a href="https://app.exchanity.com/" class="btn btn-primary">{{$i18n.t('main.signUp')}}</a>
        </div>
      </div>
    </div>
  </section>
  <section class="offer">
    <div class="container">
      <div class="row">
        <div class="col-xxl-2 py-3"><h2>{{$i18n.t('product.offer')}}</h2></div>
        <div class="col-xxl-5 col-md-6 py-3 pe-md-5">
          <h6>{{$i18n.t('product.offerH1')}}</h6>
          <h6>{{$i18n.t('product.offerH12')}}</h6>
          <p class="text-default py-2">{{$i18n.t('product.offerText1')}}</p>
        </div>
        <div class="col-xxl-5 col-md-6 py-3"><img src="img/product2-1.svg" alt=""></div>
        <div class="col-xxl-5 col-md-6 py-3 pe-md-5 offset-xxl-2">
          <h6>{{$i18n.t('product.offerH2')}}</h6>
          <p class="text-default py-2">{{$i18n.t('product.offerText2')}}</p>
          <p class="text-default py-2">{{$i18n.t('product.offerText22')}}</p>
        </div>
        <div class="col-xxl-5 col-md-6 py-3"><img src="img/product2-2.svg" alt=""></div>
        <div class="col-xxl-5 col-md-6 py-3 pe-md-5 offset-xxl-2">
        <h6>{{$i18n.t('product.offerH3')}}</h6>
        <p class="text-default py-2">{{$i18n.t('product.offerText3')}}</p>
        <p class="text-default py-2">{{$i18n.t('product.offerText32')}}</p>
      </div>
        <div class="col-xxl-5 col-md-6 py-3"><img src="img/product2-3.svg" alt=""></div>
    </div>
    </div>
  </section>
  <section class="assets">
    <div class="container">
      <div class="row">
        <div class="col-md-2" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
      </div>
      <h2 class="mb-4 d-flex justify-content-between">
        {{ $i18n.t('main.assets') }}
        <a class="f-b crypto__link" target="_blank" href=""> {{ $i18n.t('main.viewAll') }} </a>
      </h2>
      <digital-assets></digital-assets>
    </div>
  </section>
  <section class="fees">
    <div class="container">
      <div class="row">
        <div class="col-md-2" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
        <div class="col-2 d-md-block d-none" ><div class="separator mb-5" disabled></div></div>
      </div>
      <div class="row py-3">
        <div class="col-xl-2">
          <h2>{{ $i18n.t('product.fees') }}</h2>
        </div>
        <div class="col-xl-2 col-md-4 py-4"><p class="text-default"><b>{{ $i18n.t('product.feesText1') }}</b></p></div>
        <div class="col-md-8 px-md-4 py-4"><img :src="imageUrl1" alt=""></div>
        <div class="col-xl-2 col-md-4 py-4 offset-xl-2"><p class="text-default"><b>{{ $i18n.t('product.feesText2') }}</b></p></div>
        <div class="col-md-8 px-md-4 py-4"><img :src="imageUrl2" alt=""></div>
        <div class="col-xl-2 col-md-4 py-4 offset-xl-2"><p class="text-default"><b>{{ $i18n.t('product.feesText3') }}</b></p></div>
        <div class="col-md-8 px-md-4 py-4"><img :src="imageUrl3" alt=""></div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import digitalAssets from "@/components/digital-assets";

export default {
  name: "Products",
  components: { digitalAssets },
  methods:{},
  computed: {
    imageUrl1() {
      return this.$i18n.locale === 'en' ? 'img/feel1.svg' : 'img/feel1r.svg';
    },
    imageUrl2() {
      return this.$i18n.locale === 'en' ? 'img/feel2.svg' : 'img/feel2r.svg';
    },
    imageUrl3() {
      return this.$i18n.locale === 'en' ? 'img/feel3.svg' : 'img/feel3r.svg';
    }
  },
  mounted() {
    if (this.$route.params.scroll === 'fees'){
      document.querySelector('.fees').scrollIntoView({
        behavior: 'smooth'
      });
    }else {
      document.querySelector('body').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>

</style>
