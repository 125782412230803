<template>
  <div
      class="w-100 align-items-start scrolled__block d-flex  justify-content-around To1"
      ref="mainBlock"
      v-touch:swipe.prevent.stop="swipe"
  >
    <div class="annotation text__slides align-self-center works__image__box">
      <div ref="text1" class="d-flex align-items-md-center align-items-end active">
        <p>
          {{ $i18n.t('howWorks.verification') }}:
          <br>
          <br class="d-none d-md-inline">
          {{ $i18n.t('howWorks.card') }} <br class="d-none d-md-inline"> <b class="d-md-none d-inline">/</b>
          {{ $i18n.t('howWorks.license') }} <br class="d-none d-md-inline"> <b class="d-md-none d-inline">/</b>
          {{ $i18n.t('howWorks.passport') }}
        </p>
      </div>
      <div ref="text2" class="d-flex align-items-md-center align-items-end">
        <p>{{ $i18n.t('howWorks.address') }}:
          <br>
          <br class="d-none d-md-inline">
          {{ $i18n.t('howWorks.bill') }} <br class="d-none d-md-inline"><b class="d-md-none d-inline">/</b>
          {{ $i18n.t('howWorks.invoice') }} <br class="d-none d-md-inline"><b class="d-md-none d-inline">/</b>
          {{ $i18n.t('howWorks.statement') }}</p>
      </div>
      <div ref="text3" class="d-flex align-items-md-center align-items-end">
       <p> {{ $i18n.t('howWorks.authentication') }}</p>
      </div>
    </div>
    <div class="works__image__box img__slides d-flex justify-content-start flex-column"
         @wheel.stop.prevent="scroll"
    >
      <div ref="img1" class="d-flex justify-content-center align-items-center overflow-hidden active"><img
          src="img/works1.svg" alt=""></div>
      <div ref="img2" class="d-flex justify-content-center align-items-center overflow-hidden "><img
           src="img/works0-slide2.svg" class="cust__el" alt=""></div>
      <div ref="img3" class="d-flex justify-content-center align-items-center overflow-hidden"><img
          src="img/works0-slide3.svg" alt=""></div>
    </div>
    <div class="annotation d-none d-md-block works__image__box"></div>
  </div>
</template>

<script>
export default {
  name: "scrolled-slide",
  props: ['activeSlide'],
  watch: {
    activeSlide() {
      if (this.activeSlide === 1) {
        clearInterval(this.interval);
        this.index = 1;
        this.$refs.mainBlock.classList.remove('To2', 'To3');
        this.$refs.mainBlock.classList.add('To1');
        this.$refs.img2.classList.remove('active');
        this.$refs.img3.classList.remove('active');
        this.$refs.img1.classList.add('active');
        this.$refs.text2.classList.remove('active');
        this.$refs.text3.classList.remove('active');
        this.$refs.text1.classList.add('active');

        this.play(this.carouselStep)
      }
    }
  },
  data() {
    return {
      interval: null,
      index: 1,
      carouselStep: 4000,
      imageStep: 750,
      isScrolled: true,
    }
  },
  computed: {},
  methods: {
    changeImg(index, time) {
      this.$refs[`img${index}`].classList.remove('active');
      setTimeout(() => {
        if (index === 3) this.$refs[`img1`].classList.add('active');
        else this.$refs[`img${index + 1}`].classList.add('active');
      }, time)
    },
    translateImages(index) {
      if (index === 3) this.$refs.mainBlock.classList.add('To1');
      else this.$refs.mainBlock.classList.add(`To${index + 1}`);
      this.$refs.mainBlock.classList.remove(`To${index}`);
    },
    changeText(index) {
      this.$refs[`text${index}`].classList.remove('active');
      if (index === 3) this.$refs[`text1`].classList.add('active');
      else this.$refs[`text${index + 1}`].classList.add('active');
    },
    fromTo(i) {
      this.changeImg(i, this.imageStep);
      this.translateImages(i);
      this.changeText(i)
    },
    toPrev(index, imgTime){
      this.$refs[`img${index}`].classList.remove('active');
      setTimeout(() => {
        if (index === 1) this.$refs[`img3`].classList.add('active');
        else this.$refs[`img${index - 1}`].classList.add('active');
      }, imgTime)
      //
      if (index === 1) this.$refs.mainBlock.classList.add('To3');
      else this.$refs.mainBlock.classList.add(`To${index - 1}`);
      this.$refs.mainBlock.classList.remove(`To${index}`);
      //
      this.$refs[`text${index}`].classList.remove('active');
      if (index === 1) this.$refs[`text3`].classList.add('active');
      else this.$refs[`text${index - 1}`].classList.add('active');
    },
    play(interval) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.fromTo(this.index);
        this.index = this.index === 3 ? 1 : this.index + 1;
      }, interval)
    },
    swipe(event) {
        clearInterval(this.interval);
        if (event === 'left' || event === 'top'){
          this.fromTo(this.index);
          this.index = this.index ===3 ? 1 : this.index+1;
        }else if(event === 'right' || event === 'bottom'){
          this.toPrev(this.index, this.imageStep);
          this.index = this.index ===1 ? 3 : this.index-1;
        }
        this.play(this.carouselStep);
    },
    scroll(e) {
      if (this.isScrolled) {
        this.isScrolled = false;
        setTimeout(()=>{this.isScrolled=true}, 2000)
        clearInterval(this.interval);
        if (e.deltaY >= 0) {
          this.fromTo(this.index);
          this.index = this.index === 3 ? 1 : this.index + 1;
        } else {
          this.toPrev(this.index, this.imageStep);
          this.index = this.index === 1 ? 3 : this.index - 1;
        }
        this.play(this.carouselStep);
      }
    }
  },
  created() {
  },
}
</script>

<style scoped>
.scrolled__block {
  height: 361px;
}

.scrolled__block .img__slides {
  transition: 2s;
}

.img__slides div {
  height: 361px;
  transition: .5s;
  opacity: 0;

}

.img__slides .active {
  transition: 1.5s;
  opacity: 1;
}

.scrolled__block.To2 .img__slides {
  transform: translateY(-361px);
}

.scrolled__block.To3 .img__slides {
  transform: translateY(-722px);
}

.text__slides {
  padding: 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.text__slides > div {
  padding-left: 10px;
  height: 126px;
  position: absolute;
  left: 0;
  top: calc(50% - 63px);
  opacity: 0;
  transition: 2s;
  transform: translateX(-400px);

}

.text__slides > .active {
  opacity: 1;
  transform: translateX(0);
}
.cust__el{
  margin-top: -40px;
}
@media screen and (max-width: 767px){
  .text__slides > div{
   top: auto;
    bottom: 0;
    min-width: 300px;
  }

.img__slides div img{
  margin-top: -50px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
  .img__slides div img.cust__el{
    margin-top: -110px;
  }
  .img__slides div, .scrolled__block{
    height: 300px;
  }
  .scrolled__block.To2 .img__slides {
    transform: translateY(-300px);
  }
  .scrolled__block.To3 .img__slides {
    transform: translateY(-600px);
  }
  }
@media screen and (max-width: 425px){
  .img__slides div, .scrolled__block{
    height: 250px;
  }
  .scrolled__block.To2 .img__slides {
    transform: translateY(-250px);
  }
  .scrolled__block.To3 .img__slides {
    transform: translateY(-500px);
  }
}
</style>
